import {Grid, Typography} from '@material-ui/core'
import {ErrorMessage, Field} from '@atlaskit/form'
import {getValidationState, requiredValidation} from 'trello-shared-resources/dist/modules/utility/Validations'
import React, {useRef} from 'react'
import Select from '@atlaskit/select'
import {useStyles} from './LogWorkTimeStyles'
import {ListCardAndMemberWorklogFieldsProperties} from '../../types/LogProperties'
import {validateList} from '../../modules/Utils'


export const ListCardAndMemberWorklogFields = (props: ListCardAndMemberWorklogFieldsProperties) => {

    const {listOptions, cardOptions, cardSelected, setCardSelected, cardsSelectedInList, memberOptions, logWorkFromCard, memberRef,
        setCardsByListId, memberSelected, setMemberSelected, listSelected, setListSelected} = props

    const classes = useStyles()

    const cardSelectRef = useRef()
    const resetCardList = useRef<boolean>(false)

    const requiredErrorMessage = <ErrorMessage>This field is required</ErrorMessage>
    const notFoundListErrorMessage = <ErrorMessage>There are no lists created on this board</ErrorMessage>

    /**
     * Load a select component
     * @param id select component
     * @param rest field properties
     * @param error field
     * @param valid if the field is valid or not
     */
    const selectField = (id: any, rest: any, error: any, valid: boolean) => {
        const resetCardListValidation = resetCardList.current
        if (rest.name === 'card' && resetCardListValidation) resetCardList.current = false
        return (<>
            <Select
                {...rest}
                inputId={id}
                validationState={(rest.name !== 'card' || (rest.name === 'card' && !resetCardListValidation)) ? getValidationState(error, valid) : undefined}
                onChange={(value: any) => {
                    if (rest.customOnChange) rest.customOnChange(value)
                    rest.onChange(value)
                }
                }
                onFocus={() => resetCardList.current = false}
            />

            {error === 'EMPTY' &&
                /* istanbul ignore next */
                (rest.name !== 'card' || (rest.name === 'card' && !resetCardListValidation)) &&
                /* istanbul ignore next */
                requiredErrorMessage}
            {error === 'NOT_FOUND_LISTS' &&
                /* istanbul ignore next */
                notFoundListErrorMessage}
        </>)
    }

    /**
     * On change select list
     * @param option value selected
     */
    const onChangeList = (option: any) => {
        setListSelected(option)
        setCardsByListId(option.value, cardsSelectedInList)
        resetCardList.current = true
        // @ts-ignore
        cardSelectRef.current.select.clearValue()
    }

    const requiredText = <Grid item xs={6}>
        <Typography className={classes.requiredText}><Typography
            component={'span'}>*</Typography> indicates a
            required
            field</Typography>
    </Grid>

    return <>
        {!logWorkFromCard &&
        <Grid container justifyContent="flex-start" spacing={4} className={classes.listAndCardRow}>
            <Grid item xs={6}>
                <Field name="list" label="List" isRequired validate={(value) => validateList(value, listOptions)} defaultValue={listSelected}>
                    {({
                          fieldProps: {id, ...rest},
                          error,
                          meta: {valid}
                      }) => selectField(id, {...rest, placeholder: 'Select a list...', options: listOptions,
                        customOnChange: onChangeList}, error, valid)}
                </Field>
            </Grid>
            {requiredText}
        </Grid>
        }
        <Grid container spacing={3} justifyContent="flex-start">
            {!logWorkFromCard &&
            <Grid item xs={6}>
                <Field name="card" label="Card" isRequired validate={requiredValidation} defaultValue={cardSelected}>
                    {({
                          fieldProps: {id, ...rest},
                          error,
                          meta: {valid},
                      }) => selectField(id, {...rest, placeholder: 'Select a card...', ref: cardSelectRef,
                        options: cardOptions, customOnChange: setCardSelected}, error, valid)}
                </Field>
            </Grid>
            }
            <Grid item xs={6}>
                <Field name="member" label="Member" isRequired validate={requiredValidation} defaultValue={memberSelected}>
                    {({
                          fieldProps: {id, ...rest},
                          error,
                          meta: {valid}
                      }) => selectField(id, {...rest, placeholder: 'Select a member...', options: memberOptions, ref: memberRef,
                        customOnChange: setMemberSelected}, error, valid)}
                </Field>
            </Grid>
            {logWorkFromCard && requiredText}
        </Grid>
    </>
}