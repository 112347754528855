import React from 'react'
import AtlasButton from '@atlaskit/button'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import {DownloadPickerProperties} from '../../types/ReportProperties'
import {Button, Dropdown, Menu} from 'antd'
import {supportUrl, TrackActionEvent} from 'trello-shared-resources/dist'
import CSVDownload from '../../modules/download/CSVDownload'
import MSExcelDownload from '../../modules/download/MSExcelDownload'
import {MessageType} from '../../error/FetchDataError'
import {Link} from '@material-ui/core'
import * as Sentry from '@sentry/browser'

const DownloadPicker = (props: DownloadPickerProperties) => {
    const {downloadData, licenseDetails, setReportDataMessage}  = props
    const trelloContext = licenseDetails.trelloIframeContext

    const onCsvDownload = () => {
        if (downloadData) {
            try {
                new CSVDownload().download(downloadData, props.board)
                const trelloContextInfo = trelloContext.getContext()
                TrackActionEvent('File Download', trelloContextInfo, {
                    board_id: trelloContextInfo.board,
                    download_type: 'csv'
                })
            } catch (error) {
                Sentry.captureException(error)
                displayErrorMessage()
            }
        }
    }

    const displayErrorMessage = () => {
        setReportDataMessage({
            title: '',
            closable: true,
            messageType: MessageType.error,
            subTitle: <>
                Sorry there was a problem with your download.
                Please try again. If the issue persists, please contact our Support team <Link href={supportUrl}
                                                                                               target="_blank">here</Link>.
            </>,
        })
    }

    const onExcelDownload = () => {
        if (downloadData) {
            try {
                new MSExcelDownload().download(downloadData, props.board)
                const trelloContextInfo = trelloContext.getContext()
                TrackActionEvent('File Download', trelloContextInfo, {
                    board_id: trelloContextInfo.board,
                    download_type: 'excel'
                })
            } catch (error) {
                Sentry.captureException(error)
                displayErrorMessage()
            }
        }
    }

    const getDropdownMenu = () => {
        return <Menu>
            <Menu.Item>
                <Button type="link"
                        size="middle"
                        onClick={onCsvDownload}>
                    Download CSV
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="link"
                        size="middle"
                        onClick={onExcelDownload}>
                    Download Excel
                </Button>
            </Menu.Item>
        </Menu>
    }

    return <Dropdown overlay={getDropdownMenu()}
                     placement="bottomRight"
                     trigger={['click']}>
        <AtlasButton
            isDisabled={!props.downloadData || props.downloadData.length === 0}
            iconBefore={<DownloadIcon primaryColor={'#42526E'} label="Download Report"/>}
        >
            Download Report
        </AtlasButton>
    </Dropdown>
}

export default DownloadPicker